import axios from "axios";

const instance = axios.create({
  baseURL: "http://103-195-7-40.cloud-xip.com:5001",
});

instance.interceptors.request.use((config) => {
  let token = window.localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = window.localStorage.getItem("token");
    return config;
  }
  return "error";
});

// instance.interceptors.response.use(undefined, (error) => {
//   return error.response.data && error.response.data.message
// });

export default instance;

// https://evrika-backend.vercel.app/api
// http://localhost:5000/api
// https://alippepro.df.r.appspot.com/api
// http://103-195-7-40.cloud-xip.com:5001
// https://alippe-backend-v1.onrender.com