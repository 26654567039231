import React, { useState,useEffect } from "react";
import styles from "./MainPage.module.css";
import aboutImg from "../../assets/images/aboutImg.png";
import licenseImg from "../../assets/images/license.jpeg";
import kubolukImg from "../../assets/images/kuboluk.png";
import personImg from "../../assets/images/mainImg.png";
import Testimonials from "../../components/Testimonials";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import { BsUpload } from "react-icons/bs";

const HomePage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Состояние для загрузки
  const [uploadProgress, setUploadProgress] = useState(0); // Прогресс загрузки в процентах
  const [error, setError] = useState(null);

  // Инициализация S3 клиента
  const s3 = new AWS.S3({
    accessKeyId: "AKIAWQUOZOEARFF3U7UZ", // замените на ваш accessKeyId
    secretAccessKey: "xLmTbyY5NZkpbFOttLRlUAiPHwcYFgx1pBdarr8e", // замените на ваш secretAccessKey
    region: "eu-north-1", // замените на ваш регион, например 'us-west-2'
  });

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => {
    setIsDialogOpen(false);
    setFile(null); // Сбрасываем выбранный файл
    setError(null); // Также сбрасываем ошибку, если была
  };

  const uploadFile = async () => {
    if (!file) return;

    setError(null);
    setIsUploading(true);
    setUploadProgress(0); // Сбрасываем прогресс

    const params = {
      Bucket: "alippebucket", // замените на имя вашего бакета
      Key: `${file.name}`, // имя файла в S3
      Body: file,
      ContentType: file.type,
    };

    const uploader = s3.upload(params);

    uploader.on("httpUploadProgress", (progress) => {
      const percent = Math.round((progress.loaded / progress.total) * 100);
      setUploadProgress(percent); // Обновляем прогресс
    });

    try {
      const data = await uploader.promise();
      console.log("Файл успешно загружен:", data);
      closeDialog(); // Закрываем модальное окно после успешной загрузки
    } catch (err) {
      console.error("Ошибка загрузки:", err);
      setError("Произошла ошибка при загрузке файла.");
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      setError("Пожалуйста, выберите видеофайл.");
      return;
    }

    setFile(acceptedFiles[0]);
    setError(null); // Очистить ошибку
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "video/*": [], // Принимаются только видеофайлы
    },
  });

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeDialog();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUploading) {
        event.preventDefault();
        event.returnValue = ""; // Показывает системное предупреждение.
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUploading]);

  return (
    <div className={styles.main}>
      <div className="container">
        <section className={styles.hero}>
          <div className={styles.heroLeft}>
            <h1 className={styles.heroTitle}>Alippe Pro</h1>
            <p className={styles.heroDescr}>
              Учурдаң талабына жооп берген билим берүү аянтчасы
            </p>
          </div>
          <div className={styles.heroRight}>
            <img src={personImg} alt="" />
          </div>
        </section>
        <p className={styles.heroLabel}>200+ мугалим биздин катарыбызда</p>
        <section id="about" className={styles.about}>
          <p className={styles.aboutUs}>
            ——— &nbsp; &nbsp; Эмне үчүн Alippe Pro?
          </p>
          <h2 className={styles.aboutTitle}>Биз кимбиз?</h2>
          <div className={styles.aboutContent}>
            <div className={styles.aboutImage}>
              <img src={aboutImg} alt="" />
            </div>
            <div className={styles.aboutText}>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>"Pro"</b> сөзү кыргызча "жаңыланган"
                деген маанини берет. <br /> <br /> Жаңыланган алиппе
                мугалимдерге: <br /> <br />{" "}
              </p>
              <ul className={styles.aboutList}>
                <li>
                  <a>Жаңыланган методикалар</a>
                </li>
                <li>
                  <a>заманбап билимдер</a>
                </li>
                <li>
                  <a>Университетте үйрөтүлгөн көндүмдөр</a>
                </li>
                <li>
                  <a>Лайфхактардын түрлөрү</a>
                </li>
                <li>
                  <a>Мотивация үчүн көнүгүүлөр</a>
                </li>
              </ul>
            </div>
          </div>
          <p className={styles.aboutFooter}>
            Жаңы муунга жараша жаңыланган методика, жаңыланган сабактар болушу
            керек!
          </p>
        </section>
      </div>
      <section className={styles.certificates}>
        <div className="container">
          <h2 className={styles.certificatesTitle}>Alippe Pro</h2>
          <p className={styles.certificatesDescr}>
            Мектепте <b>“Ал эжейдин сабагы скучный”</b> деген тизмеде болгусу
            келбеген, изденип турган мугалимдердин жоон тобу менен иштешебиз
          </p>
          <div className={styles.certificateImages}>
            <div className={styles.certificateImgLeft}>
              <img
                src={licenseImg}
                alt="Certificate 1"
                className={styles.certificateImage1}
              />
              <p className={styles.certificateImageDescr}>
                Ишмердүүлүк КР билим берүү жана илим министрлиги тарабынан
                лицензияланган
              </p>
            </div>
            <div className={styles.certificateImgRight}>
              <img
                src={kubolukImg}
                alt="Certificate 2"
                className={styles.certificateImage2}
              />
              <p className={styles.certificateImageDescr}>
                КР Кыргызпатент иш канасы тарабынан "шар окуу" методикасына
                автордук укук берилген.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Testimonials styles={styles} />
      <div className={styles.uploadBtn} onClick={openDialog}>
        <div className="">
          <BsUpload />
        </div>
        <p>Загрузить файл</p>
      </div>

      {isDialogOpen && (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={closeDialog}>
              ×
            </button>
            <h2 className={styles.modalTitle}>Загрузить видео на S3</h2>
            <div
              {...getRootProps()}
              className={`${styles.dropzone} ${
                file ? styles.dropzoneWithFile : ""
              }`}
            >
              <input {...getInputProps()} />
              <p>
                {file
                  ? "Видео выбрано. Хотите изменить?"
                  : "Перетащите видеофайл сюда или кликните для выбора файла"}
              </p>
            </div>
            {file && (
              <p className={styles.fileName}>Выбран файл: {file.name}</p>
            )}
            {error && <p className={styles.errorMessage}>{error}</p>}

            {isUploading ? (
              <div className={styles.progressContainer}>
                <p>Загрузка: {uploadProgress}%</p>
                <div className={styles.progressBar}>
                  <div
                    className={styles.progress}
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              </div>
            ) : (
              <button
                className={styles.uploadButton}
                onClick={uploadFile}
                disabled={!file}
              >
                Загрузить в S3
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
