import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout, selectIsAuth } from "../../store/slices/auth";
import styles from "./Header.module.css";
import classNames from "classnames";
import logo from "../../assets/images/logo.png";
import { RiAdminFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { BsUpload } from "react-icons/bs";
import AWS from "aws-sdk";

const Header = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const admin = useSelector((state) => state.auth.data);
  const user = JSON.parse(localStorage.getItem("user"));
  const [showMenu, setShowMenu] = useState(false);
  // console.log();
  const onClickLogout = () => {
    if (window.confirm("Вы действительно хотите выйти?")) {
      dispatch(logout());
      setShowMenu(false);
      window.localStorage.removeItem("token");
    }
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <button
          className={classNames(styles.hamburger, { [styles.open]: menuOpen })}
          onClick={toggleMenu}
        >
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
        </button>
        <ul className={classNames(styles.navList, { [styles.open]: menuOpen })}>
          <li className={styles.navItem}>
            <Link to="/" onClick={toggleMenu}>
              Башкы бет
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/courses" onClick={toggleMenu}>
              Курстар
            </Link>
          </li>
          {/* <li className={styles.navItem}>
            <div style={{ color: "#fff" }}>Биз жонундо</div>
          </li> */}
          <li className={styles.navItem}>
            <Link to="/testimonials" onClick={toggleMenu}>
              Ой-пикирлер
            </Link>
          </li>
          {/* <li className={styles.navItem}>
            <Link to="/conference" onClick={toggleMenu}>
              Видеоконференция
            </Link>
          </li> */}
        </ul>
        <Link to="/" className={styles.navLogo}>
          <img src={logo} alt="" />
        </Link>
        {isAuth === "USER" || isAuth === "ADMIN" ? (
          <div className={styles.buttons}>
            
            <div onClick={onClickLogout} className={styles.buttonBack}>
              Чыгуу
            </div>
            {admin ? (
              <>
                {admin?.roles[0] === "ADMIN" ? (
                  <div className={styles.button}>
                    <Link to={"/adminPanel"}>
                      <RiAdminFill size={24} />
                    </Link>
                  </div>
                ) : (
                  <div className={styles.button}>
                    <FaUser />
                    &nbsp;&nbsp;&nbsp;
                    {user.name}
                  </div>
                )}
              </>
            ) : null}
          </div>
        ) : (
          <div className={styles.buttons}>
            {/* <Link to="/registration" className={styles.button}>
              Катталуу
            </Link> */}
            <Link
              to="/login"
              className={classNames(styles.button, styles.buttonPrimary)}
            >
              Кирүү
            </Link>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
